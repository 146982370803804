/*
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-13 09:54:50
 * @LastEditTime: 2023-05-18 18:14:39
 * @Descripttion: 系统管理模块接口
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const SYSTEM = "/school/schclassscheduletime/"; //课程时间表
const ROLE = "/school/role/"; //角色管理
const MENU = "/school/menu/"; //角色管理->菜单
const SCHUSER = "/school/schuser/"; //账号管理

/*课程时间管理*/

/**
 * 课程时间列表
 * @param {object} params
 * @returns
 */
export async function timeTableList(pageindex = 1, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${SYSTEM}list`,
      { pagesize, pageindex },
      { load: true }
    );
  } catch (error) {
    return error;
  }
}
/**
 * 添加课程时间
 * @param {string} starttime 开始时间
 * @param {string} endtime 结束时间
 * @returns
 */
export async function timeTableAdd(starttime, endtime) {
  try {
    return await HTTP.post(SYSTEM + "add", {
      sccla_sch_tim_starttime: starttime,
      sccla_sch_tim_endtime: endtime,
    });
  } catch (error) {
    return error;
  }
}
/**
 * 删除课程时间
 * @param {number} sccla_sch_tim_id 时间id
 * @returns
 */
export async function timeTableRemove(sccla_sch_tim_id) {
  try {
    return await HTTP.post(SYSTEM + "delete", { sccla_sch_tim_id });
  } catch (error) {
    return error;
  }
}
/**
 * 编辑课程时间
 * @param {object} params
 * @returns
 */
export async function timeTableEdit(params) {
  try {
    return await HTTP.post(SYSTEM + "edit", params);
  } catch (error) {
    return error;
  }
}

/*角色管理*/

/**
 * 角色列表
 * @param {object} params
 * @returns
 */
export async function roleList(params) {
  try {
    return await HTTP.get(ROLE + "list", params, { load: true });
  } catch (error) {
    return error;
  }
}
/**
 * 获取权限接口节点树状
 * @param {object} params
 * @returns
 */
export async function gettreenode(params) {
  try {
    return await HTTP.post(ROLE + "gettreenode", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取角色关联的权限
 * @param {object} params
 * @returns
 */
export async function getrolenodeid(params) {
  try {
    return await HTTP.post(ROLE + "getrolenodeid", params);
  } catch (error) {
    return error;
  }
}
/**
 * 修改权限选择
 * @param {object} params
 * @returns
 */
export async function saverolenode(params) {
  try {
    return await HTTP.post(ROLE + "saverolenode", params);
  } catch (error) {
    return error;
  }
}
/**
 * 添加角色
 * @param {object} params
 * @returns
 */
export async function roleAdd(params) {
  try {
    return await HTTP.post(ROLE + "add", params);
  } catch (error) {
    return error;
  }
}
/**
 * 角色下拉
 * @param {object} params
 * @returns
 */
export async function roleselect(params) {
  try {
    return await HTTP.post(ROLE + "getkeyval", params);
  } catch (error) {
    return error;
  }
}
/**
 * 编辑角色
 * @param {object} params
 * @returns
 */
export async function roleEdit(params) {
  try {
    return await HTTP.post(ROLE + "edit", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除角色
 * @param {object} params
 * @returns
 */
export async function roleRemove(params) {
  try {
    return await HTTP.post(ROLE + "delete", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取菜单树状
 * @param {object} params
 * @returns
 */
export async function gettreemenu(params) {
  try {
    return await HTTP.post(MENU + "gettreemenu", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取菜单已授权id
 * @param {object} params
 * @returns
 */
export async function getrolemenuid(params) {
  try {
    return await HTTP.post(MENU + "getrolemenuid", params);
  } catch (error) {
    return error;
  }
}
/**
 * 保存菜单配置
 * @param {object} params
 * @returns
 */
export async function saverolemenu(params) {
  try {
    return await HTTP.post(MENU + "saverolemenu", params);
  } catch (error) {
    return error;
  }
}

/*账号管理*/

/**
 * 账号列表
 * @param {object} params
 * @returns
 */
export async function accountList(params) {
  try {
    return await HTTP.get(SCHUSER + "list", params, { load: true });
  } catch (error) {
    return error;
  }
}
/**
 * 新增账号
 * @param {object} params
 * @returns
 */
export async function accountAdd(params) {
  try {
    return await HTTP.post(SCHUSER + "add", params);
  } catch (error) {
    return error;
  }
}
/**
 * 编辑账号
 * @param {object} params
 * @returns
 */
export async function accountEdit(params) {
  try {
    return await HTTP.post(SCHUSER + "edit_data", params);
  } catch (error) {
    return error;
  }
}
/**
 * 删除账号
 * @param {object} params
 * @returns
 */
export async function accountRemove(params) {
  try {
    return await HTTP.post(SCHUSER + "delete", params);
  } catch (error) {
    return error;
  }
}
/**
 * 账号详情
 * @param {object} params
 * @returns
 */
export async function accountInfo(params) {
  try {
    return await HTTP.post(SCHUSER + "info", params);
  } catch (error) {
    return error;
  }
}
