<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-23 16:04:53
 * @LastEditTime: 2023-02-28 21:08:52
 * @Descripttion: 课程管理
-->
<style lang="scss" scoped>
.calendar {
    @include innerPage(30px 0 48px);
    @include pageTitle;

    &-change {
        margin-right: auto;
        margin-left: 100px;
        color: #484848;
        @include flexBox;
        ;

        .pf_bold {
            font-size: 24px;
            width: 140px;
        }

        .el-button {
            min-width: 32px;
            padding: 0;
            margin: 0;
            font-size: 18px;
            color: #fff;
            border-radius: 8px;
        }
    }

    .lesson-set {
        margin-right: 22px;
        @include flexBox(flex-end, flex-end);

        img {
            width: 70px;
            margin-right: 7px;
        }

        .bold {
            font-size: 16px;
            color: #484848;
            padding-bottom: 10px;
        }
        &:hover{
            cursor: pointer;
            .bold{
                color:#6c4ecb;
            }
        }
    }

    &-box {
        width: 100%;
        height: calc(100% - 50px);
        box-sizing: border-box;
        padding-top: 34px;

        .week,
        .days {
            display: flex;
            flex-wrap: wrap;
            border: solid #e2e4ed;

            li {
                width: calc(100% / 7);
                box-sizing: border-box;
                border: solid #e2e4ed;
                color: #1a1a1a;
                @include flexBox(center);
            }
        }

        .week {
            background: #eff1fa;
            border-radius: 10px 10px 0px 0px;
            border-width: 1px 1px 0 1px;

            li {
                height: 74px;
                font-size: 16px;
                border-width: 0 1px 1px 0;
                padding: 1px 0 0 1px;

                &:last-child {
                    border-right-width: 0;
                }
            }
        }

        .days {
            width: 100%;
            background: #f5f7fe;
            border-radius: 0 0 10px 10px;
            border-width: 0 1px 1px 1px;

            &-box {
                margin-top: -1px;
                width: 100%;
                max-height: calc(100% - 74px);
                border-radius: 0 0 10px 10px;
                overflow: hidden;
                overflow-y: auto;

                &.scroll {
                    box-sizing: border-box;
                    width: calc(100% + 8px);
                    padding-right: 2px;
                }
            }

            li {
                height: 140px;
                border-width: 1px 1px 0 3px;
                font-size: 18px;
                border-left-color: transparent;
                padding-right: 2px;
                padding: 0 2px 1px 0;
                position: relative;

                &::after {
                    content: '课程信息';
                    font-family: 'MicrosoftYaHei Bold';
                    font-size: 16px;
                    line-height: 24px;
                    color: #2ac293;
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    transform: scaleY(0);
                    @include defaultAni(.2s);
                }

                &:nth-child(7n) {
                    border-right-width: 0;
                }

                &:not(.disabled) {
                    cursor: pointer;
                }

                &:not(.disabled):hover {
                    background: #bfedde;
                    box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.11);
                    border-left-color: #2ac293;

                    &::after {
                        transform: scaleY(1);
                    }
                }

                &.disabled {
                    color: rgba($color: #1a1a1a, $alpha: 0.3);
                }
            }
        }
    }
}

.dialog-container {
    width: calc(100% + 72px);
    margin-left: -36px;
    padding: 30px 0 10px;
    position: relative;

    img {
        width: 100%;
    }

    p {
        width: 100%;
        text-align: center;
        color: #afafaf;
        position: absolute;
        line-height: 2em;
        bottom: 24px;
        left: 0;
    }

    &--class {
        padding: 36px 0;
        box-sizing: border-box;
    }
}
</style>

<template>
    <section class="calendar">
        <div class="page-title">
            <h3>课程日历</h3>
            <div class="calendar-change">
                <p class="pf_bold">{{ `${currentYear}年${currentMonth}月` }}</p>
                <el-button class="iconfont prev" type="custom_primary" size="mini"
                    @click="changeMonth(1)">&#xe612;</el-button>
                <el-button class="iconfont next" type="custom_warning" size="mini"
                    @click="changeMonth(2)">&#xe613;</el-button>
            </div>
            <div class="lesson-set" @click="setLesson">
                <img src="@assets/images/lesson_set.png" alt="">
                <span class="bold">课程表设置</span>
            </div>
        </div>
        <div class="calendar-box">
            <ul class="week pf_bold">
                <li v-for="(item, index) in week" :key="index">{{ item }}</li>
            </ul>
            <div class="days-box" ref="daysBox" :class="{ scroll: scroll }">
                <ul class="days pf_medium">
                    <li class="disabled" v-for="item in prevShowDays" :key="'p' + item">{{ item }}</li>
                    <li v-for="item in currentShowDays" :key="'c' + item" @click="targetLessonInfo(item)">{{ item }}
                    </li>
                    <li class="disabled" v-for="item in nextShowDays" :key="'n' + item">{{ item }}</li>
                </ul>
            </div>
        </div>
        <!-- 提示弹窗 -->
        <el-dialog title="提示" width="558px" :visible.sync="timeDialog" @close="timeDialog = false">
            <div class="dialog-container">
                <img src="@assets/images/no-data.png" alt="" />
                <p v-if="!isExist">暂未设置课程表时间，请前往设置~</p>
            </div>
            <div slot="footer" class="foot-center">
                <el-button type="custom_primary" size="medium" @click="$router.push({ name: 'SYSTEM_TIMETABLE' })">
                    去设置
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { timeTableList } from "@api/system"
export default {
    name: "lesson_calendar",
    computed: {
        route() {
            return this.$route;
        },
        /* 展示当月所有日期 */
        currentShowDays() {
            // 有3个参数，第一个是年，第二个是月，然后输入0代表当前月份   这样就可以获取天数了
            return new Date(this.currentYear, this.currentMonth, 0).getDate()
        },
        /* 展示上月部分日期 */
        prevShowDays() {
            let prevTotal = new Date(this.currentYear, this.currentMonth - 1, 0).getDate();
            let prevSurplus = new Date(this.currentYear, this.currentMonth - 1, 0).getDay();
            let prevDays = [];
            for (let index = 0; index < prevSurplus; index++) {
                prevDays.push(prevTotal - index)
            }

            return this.$_.sortBy(prevDays);
        },
        /* 展示下月部分日期 */
        nextShowDays() {
            let prevSurplus = new Date(this.currentYear, this.currentMonth - 1, 0).getDay();
            let currentDays = new Date(this.currentYear, this.currentMonth, 0).getDate()
            return 7 - (prevSurplus + currentDays) % 7
        }
    },
    data() {
        return {
            week: ['一', '二', '三', '四', '五', '六', '日'],
            currentMonth: new Date().getMonth() + 1, // 当前月
            currentYear: new Date().getFullYear(), // 当前年
            scroll: false,
            isExist: false, // 是否配置课程表时间
            timeDialog: false, // 显示弹窗
        }
    },
    created() {
        this.getTimeTable();
    },
    mounted() {
        this.initHeight();
        /* 监听窗口大小变化 */
        window.addEventListener("resize", this.initHeight);
    },
    /** 监听页面离开 */
    beforeRouteLeave(to, from, next) {
        this.$destroy();
        next();
    },
    /** 销毁页面上窗口监听事件 */
    destroyed() {
        window.removeEventListener("resize", this.initHeight);
    },
    methods: {
        /** 获取课程表时间配置数据 */
        async getTimeTable() {
            let { data: res } = await timeTableList(1, 1);
            this.isExist = res.allcount > 0;
        },
        /** 格式化最大高度 */
        initHeight() {
            this.$nextTick(() => {
                setTimeout(() => {
                    const elH = this.$refs.daysBox;
                    if (!elH) return;
                    if (elH.scrollHeight > elH.clientHeight) this.scroll = true;
                    else this.scroll = false;
                    this.$forceUpdate();
                }, 200);
            });
        },
        /** 切换展示日历月份 */
        changeMonth(type) {
            switch (type) {
                case 1:
                    if (this.currentMonth == 1) {
                        this.currentYear--;
                        this.currentMonth = 13

                    }
                    this.currentMonth--
                    break;
                case 2:
                    if (this.currentMonth == 12) {
                        this.currentYear++;
                        this.currentMonth = 0

                    }
                    this.currentMonth++
                    break;
                default:
                    break;
            }
            this.$forceUpdate();
        },
        /** 跳转课程信息 */
        targetLessonInfo(item) {
            this.$router.push({
                name: 'LESSON_INFO',
                params: { day: `${this.currentYear}/${this.currentMonth}/${item}` }
            })
        },
        /** 设置课程表 */
        setLesson() {
            if (this.isExist) {
                this.$router.push({ name: 'LESSON_SET' })
            } else {
                this.timeDialog = true;
            }
        },
    },
};
</script>
